<template>
	<b-overlay
		:show="isLoading"
		rounded="lg"
		opacity="0.6"
		spinner-variant="primary"
	>
		<template #overlay>
			<div class="d-flex align-items-center">
				<b-spinner small type="grow" variant="secondary"></b-spinner>
				<b-spinner type="grow" variant="dark"></b-spinner>
				<b-spinner small type="grow" variant="secondary"></b-spinner>
			</div>
		</template>
		<b-row class="mt-4">
			<b-col cols="12">
				<router-link class="btn btn-sm btn-default" :to="{name: AndroidTierManagePath.name}">
					<em class="fa fa-backward"></em> Manage Android Tier
				</router-link>
			</b-col>
			<b-col v-if="item">
				<b-form @submit="onSubmit">
					<div class="card mt-4">
						<div class="bg-success p-3">
							<h5 class="card-title mb-0 font-weight-bold">
								Edit Android Tier ({{ this.form.tier_code }})
							</h5>
						</div>
						<div class="card-body">
							<b-row>
								<b-col lg="6" class="mt-4">
									<b-card class="bg-info text-dark">(*) Kolom berlabel bintang wajib diisi.</b-card>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Tiercode *">
										<b-form-input placeholder="example .c.usd.3.99" id="tier_code" v-model="form.tier_code" type="text" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Price IDR *">
										<b-form-input id="price_idr" v-model.number="form.price_idr" type="number" step="any" placeholder="example 1000" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Tier Price *">
										<b-form-input id="tier_price" v-model.number="form.tier_price" type="number" step="any" placeholder="example 1.99" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Tier Type *">
										<b-form-select
											id="tier_type"
											track-by="value"
											v-model="form.tier_type"
											:options="tierTypeOptions"
											required
										>
										</b-form-select>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Tiercode Currency *">
										<multiselect
											v-model="form.currency"
											label="iso4217_code"
											track-by="id"
											placeholder="Tiercode Currency"
											open-direction="bottom"
											:options="tiercodeCurrencySearchOptions"
											:searchable="true"
											:loading="isTiercodeCurrencySearch"
											:close-on-select="true"
											:clear-on-select="false"
											:preserve-search="false"
											:preselect-first="false"
											:multiple="false"
											:taggable="false"
											@search-change="tiercodeCurrencyFind"
											required
										>
										</multiselect>
									</b-form-group>
								</b-col>
							</b-row>
							<hr />
							<b-row>
								<b-col>
									<b-form-group>
										<b-form-checkbox v-model="form.is_active" :value="true" :unchecked-value="false">
											Is Active
										</b-form-checkbox>
									</b-form-group>
								</b-col>
							</b-row>
							<br />
							<b-row>
								<b-col>
									<b-button variant="primary" type="submit">Update</b-button>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-form>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { AndroidTierManagePath } from "../../router/marketing";
import constant from "../../store/constant";

export default {
	name: "edit-android-tier",
	data() {
		return {
			debounce: null,
			AndroidTierManagePath,
			androidTier_id: null,
			form: {
				tier_code: null,
				price_idr: null,
				tier_price: null,
				tier_type: null,
				currency: null,
				is_active: false
			},
			isTiercodeCurrencySearch: false,
			tiercodeCurrencySearchOptions: [],
			tierTypeOptions: constant.TIER_TYPE_OPTION,
		};
	},
	computed: {
		...mapState({
			isError: (state) => state.tiercodeAndroid.isError,
			isLoading: (state) => state.tiercodeAndroid.isLoading,
			errorMessage: (state) => state.tiercodeAndroid.errorMessage,
			successMessage: (state) => state.tiercodeAndroid.successMessage,
			item: (state) => state.tiercodeAndroid.item,
		}),
	},
	watch: {
		errorMessage: function() {
			if (!this.errorMessage) return;
			this.messageAlert("error", this.errorMessage, 5000);
		},
		successMessage: function() {
			if (!this.successMessage) return;
			this.messageAlert("success", this.successMessage);
			Object.assign(this.$data, this.$options.data.call(this));
			this.$router.push(AndroidTierManagePath);
		},
		item: function() {
			if (!this.item) {
				this.messageAlert("error", "not found");
				return;
			}
			if (!Object.keys(this.item).length) return;
			this.setEditAndroidTier();
		},
	},
	created() {
		this.androidTier_id = this.$route.params.id;
		this.fetchAndroidTierById(this.androidTier_id);
	},
	methods: {
		...mapActions("tiercodeAndroid", ["updateAndroidTiers", "fetchAndroidTierById"]),
		...mapActions("currency", ["fetchCurrency", "AutocompleteSearch"]),
		onSubmit(event) {
			event.preventDefault();
			const data = this.form;
			const payload = {
				id: this.androidTier_id,
				field: {
					tier_code: data.tier_code,
					price_idr: data.price_idr,
					tier_price: data.tier_price,
					tier_type: data.tier_type,
					currency_id: data.currency?.id ? data.currency.id : 0,
					is_active: data.is_active ? true : false,
				},
			};
			this.updateAndroidTiers(payload);
		},
		tiercodeCurrencyFind(query) {
			if (!query) return;

			this.isTiercodeCurrencySearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.AutocompleteSearch({ q: query })
					.then((response) => {
						this.tiercodeCurrencySearchOptions = response.data.data.rows;
						this.isTiercodeCurrencySearch = false;
					})
					.catch(() => {
						this.isTiercodeCurrencySearch = false;
					});
			}, 1200);
		},
		setEditAndroidTier() {
			const data = this?.item;
			this.form.tier_code = data?.tier_code;
			this.form.price_idr = data?.price_idr;
			this.form.tier_price = data?.tier_price;
			this.form.tier_type = data?.tier_type;
			this.form.is_active = data?.is_active;
			this.androidTier_id = data?.id;

      this.fetchCurrency({ page: 1, limit: 100 }).then((response) => {
				this.tiercodeCurrencySearchOptions = response.data.data?.rows ?? [];
				let idCurrency = (response.data.data?.rows ?? []).find(
					(val) => val.id == data?.currency_id
				);
				this.form.currency = idCurrency ?? null;
      });
		},
		messageAlert(icon, title, timer = 3000) {
			this.$swal({
				toast: "true",
				position: "top-end",
				icon,
				title,
				showConfirmButton: false,
				timer,
				timerProgressBar: true,
			});
		},
	},
};
</script>
