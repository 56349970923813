var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-default",
    attrs: {
      "to": {
        name: _vm.AndroidTierManagePath.name
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward"
  }), _vm._v(" Manage Android Tier ")])], 1), _vm.item ? _c('b-col', [_c('b-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('div', {
    staticClass: "card mt-4"
  }, [_c('div', {
    staticClass: "bg-success p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v(" Edit Android Tier (" + _vm._s(this.form.tier_code) + ") ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    staticClass: "bg-info text-dark"
  }, [_vm._v("(*) Kolom berlabel bintang wajib diisi.")])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tiercode *"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "example .c.usd.3.99",
      "id": "tier_code",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.tier_code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tier_code", $$v);
      },
      expression: "form.tier_code"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Price IDR *"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "price_idr",
      "type": "number",
      "step": "any",
      "placeholder": "example 1000",
      "required": ""
    },
    model: {
      value: _vm.form.price_idr,
      callback: function ($$v) {
        _vm.$set(_vm.form, "price_idr", _vm._n($$v));
      },
      expression: "form.price_idr"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tier Price *"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "tier_price",
      "type": "number",
      "step": "any",
      "placeholder": "example 1.99",
      "required": ""
    },
    model: {
      value: _vm.form.tier_price,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tier_price", _vm._n($$v));
      },
      expression: "form.tier_price"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tier Type *"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "tier_type",
      "track-by": "value",
      "options": _vm.tierTypeOptions,
      "required": ""
    },
    model: {
      value: _vm.form.tier_type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tier_type", $$v);
      },
      expression: "form.tier_type"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tiercode Currency *"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "iso4217_code",
      "track-by": "id",
      "placeholder": "Tiercode Currency",
      "open-direction": "bottom",
      "options": _vm.tiercodeCurrencySearchOptions,
      "searchable": true,
      "loading": _vm.isTiercodeCurrencySearch,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": false,
      "preselect-first": false,
      "multiple": false,
      "taggable": false,
      "required": ""
    },
    on: {
      "search-change": _vm.tiercodeCurrencyFind
    },
    model: {
      value: _vm.form.currency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "currency", $$v);
      },
      expression: "form.currency"
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.form.is_active,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_active", $$v);
      },
      expression: "form.is_active"
    }
  }, [_vm._v(" Is Active ")])], 1)], 1)], 1), _c('br'), _c('b-row', [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)])])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }